import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import Header from 'components/Header';
import Delivery from 'components/Delivery';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';

import CustomFunc from 'addJquery';
//import './css/style.css';

class App extends Component {
    componentDidMount() {
        CustomFunc();
    }
    componentDidUpdate() {
        CustomFunc();
    }

    render() {
        const mapState = {
            center: [53.229872, 50.180754],
            zoom: 16,
        };
        return (
            <Fragment>
                <div class="wrapper" id="tophead">
                    <Header />
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="breadCrumbs">
                                    <li><Link to="/">Главная</Link></li>
                                    <li><Link className="active" to="/delivery">Оплата и доставка</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="product">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="text-content">
                                        <h2>Вкусная доставка</h2>
                                        <Delivery />
                                        <div className="map">
                                            <YMaps>
                                                <Map defaultState={mapState} style={{
                                                    width: '100%',
                                                    height: '100%'
                                                }}>
                                                    <Placemark
                                                        defaultGeometry={mapState.center}
                                                        modules={['geoObject.addon.balloon']}
                                                        properties={{
                                                            balloonContentBody:
                                                                '<b>VeryBerry</b><br/>Самара, Ново-Садовая, 265а'
                                                        }}
                                                    />
                                                </Map>
                                            </YMaps>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="text-content">
                                        <h2>Оплата</h2>
                                        <p>Оплата возможна наличными при получении своего заказа в мастерской или от курьера.</p>
                                        <p>Онлайн-переводом на карту удобного банка: Сбербанк, ТинькоффБанк, РайффайзенБанк, Альфа банк.</p>
                                        <p>Безналичный перевод на расчетный счет VeryBerry.</p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <Veryberry />
                    <div className="substrate-footer"></div>
                </div>
                <Footer />
            </Fragment>
        );
    }
};

export default withRouter(connect(null, null)(App));
