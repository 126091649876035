import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default () => {
    return (
        <Fragment>
            <h2 className="hcenter">
                <span>Вместе с этим товаром обычно покупают</span>
            </h2>
            <ul className="pokyp">
                <li>
                    <Link to="/balloons">
                        <img src="/img/ico-balloons.jpg" alt="" />
                        Перейти в Шары
                    </Link>
                </li>
                <li>
                    <Link to="/postcards">
                        <img src="/img/ico-postcards.jpg" alt="" />
                        Перейти в открытки
                    </Link>
                </li>
                <li>
                    <Link to="/balloons-postcards-toys">
                        <img src="/img/ico-toys.jpg" alt="" />
                        Перейти в игрушки
                    </Link>
                </li>
            </ul>
            <div className="clearfix"></div>
        </Fragment>
    );
}