import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app';
import bouquets from './bouquets';
import faq from './faq';
import partners from './partners';
import slides from './slides';

export default history => combineReducers({
    router: connectRouter(history),
    app,
    bouquets,
    faq,
    partners,
    slides
});