import React from 'react';
import { NavLink } from 'react-router-dom';

export default () => {
    return (
        <div className="col-sm-5">
            <div className="menu">
                <span className="touch-menu"><span></span></span>
                <ul className="nav clearfix">
                    <li><NavLink to="/bouquets">Букетики</NavLink></li>
                    <li><NavLink to="/balloons-postcards-toys">Дополнительно</NavLink></li>
                    <li><NavLink to="/delivery">Оплата и доставка</NavLink></li>
                    <li><NavLink to="/contacts">Контакты</NavLink></li>
                </ul>
            </div>
        </div>
    );
}