import React, { Fragment } from 'react';

export default () => {
    return (
        <Fragment>
            <h2 className="hcenter mt20">
                <span>Акция</span>
            </h2>
            <div className="clearfix"></div>
            <ul className="benefits bgreen">
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits8.svg" />
                    </div>
                    <div className="benefits-text">При первом заказе - открытка на выбор в подарок</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits9.svg" />
                    </div>
                    <div className="benefits-text">Более 5 заказов - скидка 5% на последующие заказы, вступление в #ВериБерри_клуб</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits10.svg" />
                    </div>
                    <div className="benefits-text">Индивидуальные условия для корпоративных клиентовы</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits11.svg" />
                    </div>
                    <div className="benefits-text">Бесплатная доставка при заказе от 5000 ₽ на один адрес в пределах города</div>
                </li>
            </ul>
            <div className="clearfix"></div>
        </Fragment>
    );
}