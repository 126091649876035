import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchBouquets } from 'actions';
import Bouquet from 'components/Bouquet';
//import R from 'ramda';

class Catalog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            limit: 16,
            offset: 0
        }
        if (props.bouquets.length === 0) this.props.fetchBouquets();
    }

    loadMore = () => {
        if (this.state.limit < this.props.bouquets.length) this.setState({ limit: this.state.limit + 16 });
    }

    render() {
        const { bouquets, location } = this.props;
        let showItems = [];
        if (this.props.showItems) showItems = this.props.showItems;

        return (
            <div className="container">
                <div className="row catalog">
                    {bouquets.filter((item, i) => {

                        if (showItems.length === 0) return true;
                        else {
                            if (showItems.indexOf(i) !== -1) return true;
                            else return false;
                        }
                    }).filter((item, i) => i < this.state.limit).map((bouquet, i) => {
                        if (showItems.length === 0) return (
                            <Bouquet
                                key={i + 1}
                                id={i + 1}
                                bouquet={bouquet}
                            />
                        );
                        else {
                            return (
                                <Bouquet
                                    key={i + 1}
                                    id={showItems[i] + 1}
                                    bouquet={bouquet}
                                />
                            );
                        }
                    })}
                    {this.state.limit < this.props.bouquets.length && location.pathname !== '/' ?
                        (<div className="col-xs-12">
                            <button className="loadmore" onClick={() => this.loadMore()}>Загрузить еще</button>
                        </div>) : null}
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        location: state.router.location,
        bouquets: state.bouquets
    };
};

const mapDispatchToProps = {
    fetchBouquets
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Catalog));
