import React from 'react';
import { NavLink } from 'react-router-dom';

export default () => {
    return (
        <div className="footer" id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-3">
                        &copy;&nbsp;2019&nbsp;Все права защищены
                </div>
                    <div className="col-sm-6">
                        <ul className="fMenu" onClick={() => window.scrollTo(0, 0)}>
                            <li><NavLink to="/">Главная</NavLink></li>
                            <li><NavLink to="/bouquets">Букетики</NavLink></li>
                            <li><NavLink to="/balloons-postcards-toys">Дополнительно</NavLink></li>
                            <li><NavLink to="/delivery">Оплата и доставка</NavLink></li>
                            <li><NavLink to="/faq">Вопросы</NavLink></li>
                            <li><NavLink to="/contacts">Контакты</NavLink></li>
                        </ul>
                        <div className="fContacks">
                            <div className="fPhone">8 927-298-94-52</div>
                            <a href="mailto:info@veryberryart.ru">info@veryberryart.ru</a>
                            г. Самара, ул.Ново-Садовая 265а
                    </div>
                    </div>
                    <div className="col-sm-3">
                        <a className="goodstudio" href="http://goodio.ru/">Разработка сайта<br />
                            Good Studio</a>
                    </div>
                </div>
            </div>
            <div className="empty">
                <a id="tophead" href="#tophead"></a>
            </div>
        </div>
    );
}
