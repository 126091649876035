import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default (props) => {

    const { name, img, price } = props.bouquet;

    let priceHtml = null;

    if (price.s) priceHtml = <Fragment><b>{price.s}</b> <i className="fas fa-ruble-sign"></i></Fragment>
    else if (price.m) priceHtml = <Fragment><b>{price.m}</b> <i className="fas fa-ruble-sign"></i></Fragment>
    else if (price.l) priceHtml = <Fragment><b>{price.l}</b> <i className="fas fa-ruble-sign"></i></Fragment>

    return (
        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <Link to={`/bouquets/${props.id}`}>
                <img alt="" src={img[0]} />
                <span>{name}</span>
            </Link>
            {priceHtml}
        </div>
    );
};
