import React, { Component, Fragment } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchFaq } from 'actions';
import Header from 'components/Header';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';

import CustomFunc from 'addJquery';
//import './css/style.css';

class Faq extends Component {
    constructor() {
        super();
        this.state = {
            faq: false
        };
    }

    componentDidMount() {
        CustomFunc();
        if (this.props.faq.length === 0) this.props.fetchFaq()
            .then(() => this.setState({ faq: this.props.faq }));
        else this.setState({ faq: this.props.faq });
    }

    componentDidUpdate() {
        CustomFunc();
    }
    render() {
        console.log(this.state.faq)
        return !this.state.faq ? null : <Fragment>
            <div className="wrapper" id="tophead">
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <ul className="breadCrumbs">
                                <li><Link to="/">Главная</Link></li>
                                <li><NavLink to="/faq">Вопрос ответ</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="product">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 ">
                                <div className="text-content">
                                    <h2 className="hcenter">
                                        <span>FAQ – ЧАсто задаваемые ВОпросы!</span>
                                    </h2>
                                    {this.state.faq.map((f, i) => <div key={i} className="quest">
                                        <div className="quest-v">{f.question}</div>
                                        <div className="quest-t">{f.answer}</div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <Veryberry />
                <div className="substrate-footer"></div>
            </div>
            <Footer />
        </Fragment>
    }
};

//export default withRouter(connect(null, null)(App));

const mapStateToProps = state => {
    return {
        location: state.router.location,
        faq: state.faq,
        loadData: state.loadData
    };
};

const mapDispatchToProps = {
    fetchFaq
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));

