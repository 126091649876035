import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header';
import Catalog from 'components/Catalog';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';

import CustomFunc from 'addJquery';

class Bouquets extends Component {

    constructor() {
        super();
        this.state = {
            showFilter: false,
            filters: {
                man: false,
                woman: false,
                child: false,
                fruit: false,
                cheese_sausage: false,
                vegetable: false,
                sweet: false,
                price1: false,
                price2: false,
                price3: false
            },
            selectedItems: []
        };
    }

    componentDidMount() {
        CustomFunc();
    }
    componentDidUpdate() {
        CustomFunc();
    }
    selectFilter = (name) => {
        const newFilters = this.state.filters;
        for (let key in newFilters) {
            newFilters[key] = false;
        }
        newFilters[name] = !this.state.filters[name]

        let bouquets_index = [];

        const { bouquets } = this.props;

        for (let key in newFilters) {
            if (newFilters[key]) {
                for (let i = 0; i < bouquets.length; i++) {
                    if (key === 'price1') {
                        if (bouquets[i].price.s && bouquets[i].price.s >= 1500 && bouquets[i].price.s < 2000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                        else if (bouquets[i].price.m && bouquets[i].price.m >= 1500 && bouquets[i].price.m < 2000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                        else if (bouquets[i].price.l && bouquets[i].price.l >= 1500 && bouquets[i].price.l < 2000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                    }
                    if (key === 'price2') {
                        if (bouquets[i].price.s && bouquets[i].price.s >= 2000 && bouquets[i].price.s < 3000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                        else if (bouquets[i].price.m && bouquets[i].price.m >= 2000 && bouquets[i].price.m < 3000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                        else if (bouquets[i].price.l && bouquets[i].price.l >= 2000 && bouquets[i].price.l < 3000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                    }
                    if (key === 'price3') {
                        if (bouquets[i].price.s && bouquets[i].price.s >= 3000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                        else if (bouquets[i].price.m && bouquets[i].price.m >= 3000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                        else if (bouquets[i].price.l && bouquets[i].price.l >= 3000) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                    }
                    else {
                        if (bouquets[i][key]) {
                            if (bouquets_index.indexOf(i) === -1) {
                                bouquets_index.push(i);
                            }
                        }
                    }
                }
            }
        }
        bouquets_index.sort((a, b) => a - b);
        this.setState({
            selectedItems: bouquets_index,
            filters: newFilters
        });
    }

    render() {
        return (
            <Fragment>
                <div class="wrapper" id="tophead">
                    <Header />
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="breadCrumbs">
                                    <li><Link to="/">Главная</Link></li>
                                    <li><Link className="active" to="/bouquets">Букетики</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <h1 className="hcenter mt30">
                        <span>Букетики</span>
                    </h1>

                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div
                                    className={this.state.showFilter ? 'filter-a active' : 'filter-a'}
                                    onClick={() => this.setState({ showFilter: !this.state.showFilter })}
                                >Фильтр</div>
                                {this.state.showFilter ? (
                                    <div className="filter animated active">
                                        <ul className="filter-list">
                                            <li>
                                                <button className={this.state.filters.man ? 'active' : null} onClick={() => this.selectFilter('man')}>Мужские</button></li>
                                            <li><button className={this.state.filters.woman ? 'active' : null} onClick={() => this.selectFilter('woman')}>Женские</button></li>
                                            <li><button className={this.state.filters.child ? 'active' : null} onClick={() => this.selectFilter('child')}>Детские</button></li>
                                        </ul>
                                        <ul className="filter-list">
                                            <li><button className={this.state.filters.price1 ? 'active' : null} onClick={() => this.selectFilter('price1')}>1500<i className="fas fa-ruble-sign"></i> - 2000<i className="fas fa-ruble-sign"></i></button></li>
                                            <li><button className={this.state.filters.price2 ? 'active' : null} onClick={() => this.selectFilter('price2')}>2000<i className="fas fa-ruble-sign"></i> - 3000<i className="fas fa-ruble-sign"></i></button></li>
                                            <li><button className={this.state.filters.price3 ? 'active' : null} onClick={() => this.selectFilter('price3')}>больше 3000<i className="fas fa-ruble-sign"></i></button></li>
                                        </ul>
                                        <ul className="filter-list">
                                            <li><button className={this.state.filters.fruit ? 'active' : null} onClick={() => this.selectFilter('fruit')}>Фруктовые</button></li>
                                            <li><button className={this.state.filters.vegetable ? 'active' : null} onClick={() => this.selectFilter('vegetable')}>Овощные</button></li>
                                            <li><button className={this.state.filters.cheese_sausage ? 'active' : null} onClick={() => this.selectFilter('cheese_sausage')}>Сыро-колбасные</button></li>
                                            <li><button className={this.state.filters.sweet ? 'active' : null} onClick={() => this.selectFilter('sweet')}>Сладкие</button></li>
                                        </ul>
                                    </div>)
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>

                    <Catalog showItems={this.state.selectedItems} />
                    <Veryberry />
                    <div className="substrate-footer"></div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        bouquets: state.bouquets,
    };
};

const mapDispatchToProps = {
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bouquets));
