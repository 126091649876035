import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from 'components/Header';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';
//import './css/style.css';

class App extends Component {
    render() {
        return (
            <Fragment>
                <div class="wrapper" id="tophead">
                    <Header />
                    <div className="clearfix"></div>
                    <div className="info-fon">
                        <img alt="" src="/img/element/1.png" id="element1" />
                        <img alt="" src="/img/element/2.png" id="element2" />
                        <img alt="" src="/img/element/3.png" id="element3" />
                        <img alt="" src="/img/element/4.png" id="element4" />
                        <img alt="" src="/img/element/5.png" id="element5" />
                        <img alt="" src="/img/element/6.png" id="element6" />

                        <Veryberry />
                    </div>
                    <div className="empty">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6"></div>
                                <div className="col-lg-4 col-sm-6"></div>
                                <div className="col-lg-4 hidden-md hidden-sm hidden-xs"></div>
                            </div>
                        </div>
                    </div>
                    <div className="substrate-footer"></div>
                </div>
                <Footer />
            </Fragment>
        );
    }
};

export default withRouter(connect(null, null)(App));
