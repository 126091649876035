import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Menu from 'components/Menu';

const Header = (props) => {

    const { location } = props;

    return <Fragment>
        <header>
            <div className="container">
                <div className="row">
                    <Menu />
                    <div className="col-sm-5 top-right">
                        <div className="top-phone">
                            <span>Позвонить</span>
                            <a href="tel:+79272989452">8 927-298-94-52</a>
                        </div>
                        <NavLink to="/faq" className="top-question">FAQ</NavLink>
                        <div className="top-icon">
                            <a href="https://vk.com/veryberryart" className="top-vk" target="_blank" rel="noopener noreferrer"><img alt="" src="/img/vk.png" /></a>
                            <a href="https://www.instagram.com/veryberry_art/" className="top-inst" target="_blank" rel="noopener noreferrer"><img alt="" src="/img/inst.png" /></a>
                        </div>
                    </div>
                    <div className={location.pathname === '/' ? 'logo' : 'logo logomini'}>
                        <Link to="/"><img alt="" src="/img/logo.svg" /></Link>
                    </div>
                </div>
            </div>
        </header>
        <div className="clearfix"></div>
    </Fragment>
}

const mapStateToProps = (state) => {
    return {
        location: state.router.location
    };
};

export default withRouter(connect(mapStateToProps, null)(Header));
