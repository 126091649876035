import React, { Fragment } from 'react';

export default () => {
    return (
        <Fragment>
            <p className="green_text"><strong>Заботливая  курьерская доставка в любое удобное место!</strong></p>
            <p>Доставка выполняется  по городу Самаре <strong>с 09:00 до 22:00</strong>,<br />
                более раннее или более позднее время оговаривается индивидуально.</p>
            <p>Стоимость доставки в пределах города Самары <strong>300 рублей</strong>.</p>
            <p>Доставка в отдаленные районы - п.Мехзавод,  п.Управленческий, п.Красная Глинка, п.Кошелев (Крутые Ключи), п.Кряж, 116 км, п.Волгарь, п.Сухая Самарка, Южный Город составит <strong>500 рублей</strong>.</p>
            <p><strong>Самовывоз</strong> возможен в удобное для Вас и согласованное с нами время из мастерской «VeryBerry» по адресу: г.Самара, ул.Ново-Садовая, д.265а.</p>
            <p className="green_text">Мы всегда готовы пойти на встречу и найти оптимально удобный вариант, чтобы подготовка к важным событиям Вашей жизни и жизни Ваших близких прошла максимально  комфортно!</p>
        </Fragment>
    );
}