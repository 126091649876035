import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';

import CustomFunc from 'addJquery';

export default class Toys extends Component {

    componentDidMount() {
        CustomFunc();
    }
    componentDidUpdate() {
        CustomFunc();
    }
    render() {
        return (
            <Fragment>
                <div class="wrapper" id="tophead">
                    <Header />
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="breadCrumbs">
                                    <li><Link to="/">Главная</Link></li>
                                    <li><Link to="/balloons-postcards-toys">Дополнительно</Link></li>
                                    <li><Link className="active" to="/toys">Игрушки</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <h1 className="hcenter mt30">
                        <span>Игрушки</span>
                    </h1>
                    <div className="container">
                        <div className="row toys">
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/toys/1.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/toys/2.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/toys/3.jpg" />
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <Veryberry />
                    <div className="substrate-footer"></div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}