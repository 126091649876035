import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from 'components/Header';
import Banner from 'components/Banner';
import Catalog from 'components/Catalog';
import ForBusiness from 'components/ForBusiness';
import Benefits from 'components/Benefits';
import Actions from 'components/Actions';
import Partners from 'components/Partners';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';

import CustomFunc from 'addJquery';
//import './css/style.css';

class Home extends Component {
    componentDidMount() {
        CustomFunc();
    }
    componentDidUpdate() {
        CustomFunc();
    }
    render() {
        return (
            <Fragment>
                <div className="wrapper" id="tophead">
                    <Header />
                    <div className="catalogitems" style={{
                        background: 'url(/img/gl-fon.jpg) center 450px no-repeat'
                    }}>
                        <Banner />

                        <h1 className="hcenter">
                            <span>#ВериБерри</span>
                        </h1>
                        <div className="clearfix"></div>
                        <Catalog />
                    </div >
                    <div className="info-fon">
                        <img alt="" src="/img/element/1.png" id="element1" />
                        <img alt="" src="/img/element/2.png" id="element2" />
                        <img alt="" src="/img/element/3.png" id="element3" />
                        <img alt="" src="/img/element/4.png" id="element4" />
                        <img alt="" src="/img/element/5.png" id="element5" />
                        <img alt="" src="/img/element/6.png" id="element6" />

                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <Benefits />
                                    <Actions />
                                    <ForBusiness />
                                </div>
                            </div>
                        </div>
                        <Partners />
                        <Veryberry />
                    </div>
                    <div className="empty">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6"></div>
                                <div className="col-lg-4 col-sm-6"></div>
                                <div className="col-lg-4 hidden-md hidden-sm hidden-xs"></div>
                            </div>
                        </div>
                    </div>
                    <div className="substrate-footer"></div>
                </div>
                <Footer />
            </Fragment>
        );
    }
};

export default withRouter(connect(null, null)(Home));
