import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import Modal from 'react-awesome-modal';
import request from 'request';

import { fetchBouquets } from 'actions';
import Header from 'components/Header';
import Delivery from 'components/Delivery';
import UsuallyBuy from 'components/UsuallyBuy';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';

import CustomFunc from 'addJquery';

class Bouquet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bouquet: false,
            checkedPrice: false,
            visible: false,
            name: '',
            phone: '',
            email: '',
            form: 'notSent',// notSend, sent, error
            error: {
                name: false,
                phone: false,
                email: false,
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        CustomFunc();
        if (this.props.bouquets.length === 0) {
            console.log('this.props.bouquets.length', this.props.bouquets.length)
            this.props.fetchBouquets()
                .then(this.getInfo);
        } else this.getInfo();
    }

    openModal = () => {
        this.setState({
            visible: true
        });
    }

    closeModal = () => {
        this.setState({
            visible: false
        });
    }
    submitForm = (e) => {
        e.preventDefault();
        const error = {};
        if (this.state.name.trim() === '') {
            error.name = true;
        }
        if (this.state.phone.trim() === '') {
            error.phone = true;
        }
        if (this.state.email.trim() === '') {
            error.email = true;
        }
        this.setState({ error }, () => {
            if (Object.keys(this.state.error).length === 0) {
                this.sendOrder();
                this.closeModal();
            }
        });
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    sendOrder = async () => {
        if (this.state.bouquet) {
            const options = {
                url: `${window.location.protocol}//${window.location.host}/kx4b5qdnyf`,
                method: 'POST',
                form: {
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.email,
                    url: window.location.href,
                    nameItem: this.state.bouquet.name,
                    price: this.state.bouquet.price[this.state.checkedPrice]
                }
            };
            try {
                await request(options, (err, res, body) => {
                    if (!err && res.statusCode === 200) {
                        const resBody = JSON.parse(body);
                        if (resBody.status === 'ok') {
                            this.setState({ form: 'sent' }, this.openModal);
                        }
                        if (resBody.status === 'error') {
                            this.setState({ form: 'error' }, this.openModal);
                        }
                        console.log(resBody);
                    }
                    else this.setState({ form: 'error' }, this.openModal);
                });
            } catch (e) {
                this.setState({ form: 'error' }, this.openModal);
            }
        }
    }

    getInfo = () => {
        const id = +this.props.match.params.id - 1;
        if (this.props.bouquets && this.props.bouquets.length > 0) {
            if (this.props.bouquets[id]) {
                this.setState({ bouquet: this.props.bouquets[id] });
                const { price } = this.props.bouquets[id]
                for (let key in price) {
                    if (key === 's') this.setState({ checkedPrice: 's' });
                    else if (key === 'm' && price.s === undefined) this.setState({ checkedPrice: 'm' });
                    else if (key === 'l' && price.s === undefined && price.m === undefined) this.setState({ checkedPrice: 'l' });
                }
            }
        }
    };


    render() {

        const item = this.state.bouquet;
        const carouselSettings = {
            showArrows: false,
            infiniteLoop: false,
            useKeyboardArrows: true,
            autoPlay: false,
            swipeable: true,
            emulateTouch: true,
            showStatus: false,
            showThumbs: true,
            dynamicHeight: true
        };
        if (item) {
            carouselSettings.showThumbs = item.img.length < 2 ? false : true;
            carouselSettings.showIndicators = item.img.length < 2 ? false : true;
        }

        return !item ? null : <Fragment>
            <div className="wrapper" id="tophead">
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <ul className="breadCrumbs">
                                <li><Link to="/">Главная</Link></li>
                                <li><Link to="/bouquets">Букетики</Link></li>
                                <li><Link className="active" to={`/bouquets/${item.id}`}>{item.name}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>

                <div className="product">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-5">
                                <Carousel className="product-img" {...carouselSettings}>
                                    {item.img.map((img, i) => <img key={i} src={img} alt="" />)}
                                </Carousel>
                            </div>
                            <div className="col-sm-7">
                                <div className="product_info">
                                    <h1>{item.name}</h1>
                                    <ul className="tab-menu">
                                        <li className="active"><a className="tab-menu_list" href="">О товаре</a></li>
                                        <li><a className="tab-menu_list" href="">Доставка и оплата</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-content-list" dangerouslySetInnerHTML={{ __html: item.description }} />
                                        <div className="tab-content-list">
                                            <Delivery />
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <h3>Размер букета</h3>
                                    <ul className="size-sm">
                                        {item.price.s ?
                                            (<li className={this.state.checkedPrice === 's' ? 'active' : null}>
                                                <span
                                                    className="size-sm_list"
                                                    onClick={() => this.setState({ checkedPrice: 's' })}>
                                                    <b>S</b>20 см.
                                                    </span>
                                            </li>) : null}
                                        {item.price.m ?
                                            (<li className={this.state.checkedPrice === 'm' ? 'active' : null}>
                                                <span
                                                    className="size-sm_list"
                                                    onClick={() => this.setState({ checkedPrice: 'm' })}
                                                ><b>M</b>25 см.</span>
                                            </li>) : null}
                                        {item.price.l ?
                                            (<li className={this.state.checkedPrice === 'l' ? 'active' : null}>
                                                <span
                                                    className="size-sm_list"
                                                    onClick={() => this.setState({ checkedPrice: 'l' })}
                                                ><b>L</b>30 см.</span>
                                            </li>) : null}
                                    </ul>
                                    <div className="clearfix"></div>
                                    {this.state.checkedPrice ?
                                        (<div className="print">
                                            Цена:
                                                <span className="print-list">{item.price[this.state.checkedPrice]}</span>
                                            <span style={{ fontSize: 20 }}><i className="fas fa-ruble-sign"></i></span>
                                        </div>)
                                        : null}
                                    <button onClick={() => this.openModal({ status: 'send' })}>Сделать заказ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="clearfix"></div>

                <UsuallyBuy />
                <Veryberry />
                <div className="substrate-footer"></div>
            </div>
            <Footer />

            <Modal
                visible={this.state.visible}
                effect="fadeInDown"
                onClickAway={this.closeModal}
            >
                {this.state.form === 'notSent' ?
                    (<form className="modal" onSubmit={this.submitForm}>
                        <div title="Закрыть" className="close-modal" onClick={this.closeModal}>×</div>
                        <h2>Заказ</h2>
                        <p>Наименование: {item.name}</p>
                        {this.state.checkedPrice ? <p>Цена: {item.price[this.state.checkedPrice]} <span style={{ fontSize: 20 }}><i className="fas fa-ruble-sign"></i></span></p> : null}
                        {this.state.checkedPrice ? <p>Размер: {this.state.checkedPrice.toUpperCase()}</p> : null}
                        <input
                            type="text"
                            name="name"
                            placeholder="Имя"
                            value={this.state.name}
                            onChange={this.handleChange} />
                        {this.state.error.name ? <div className="error">Укажите имя</div> : null}
                        <input
                            type="phone"
                            name="phone"
                            placeholder="Телефон"
                            value={this.state.phone}
                            onChange={this.handleChange} required />
                        {this.state.error.phone ? <div className="error">Укажите телефон</div> : null}
                        <input
                            type="email"
                            name="email"
                            placeholder="Электронная почта"
                            value={this.state.email}
                            onChange={this.handleChange} />
                        {this.state.error.email ? <div className="error">Укажите электронную почту</div> : null}
                        <button onClick={this.submitForm}>Отправить</button>
                    </form>)
                    : this.state.form === 'sent' ?
                        (
                            <div className="modal">
                                <div title="Закрыть" className="close-modal" onClick={this.closeModal}>×</div>
                                <h2>Заказ отправлен</h2>
                                <p>Спасибо, заказ успешно отправлен!</p>
                                <p>В ближайшее время мы свяжемся с Вами!</p>
                            </div>
                        )
                        : this.state.form === 'error' ?
                            (
                                <div className="modal">
                                    <div title="Закрыть" className="close-modal" onClick={this.closeModal}>×</div>
                                    <h2>Ошибка ☹</h2>
                                    <p>Произошла неизвестная ошибка, попробуйте позже.</p>
                                </div>
                            )
                            : null}
            </Modal>
        </Fragment>
    }
}


const mapStateToProps = state => {
    return {
        location: state.router.location,
        bouquets: state.bouquets,
        loadData: state.loadData
    };
};

const mapDispatchToProps = {
    fetchBouquets
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bouquet));
