import React, { Fragment } from 'react';

export default () => {
    return (
        <Fragment>
            <h2 className="hcenter">
                <span>Преимущества</span>
            </h2>
            <div className="clearfix"></div>
            <ul className="benefits">
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits1.svg" />
                    </div>
                    <div className="benefits-text">Только отборные и свежие ингредиенты</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits2.svg" />
                    </div>
                    <div className="benefits-text">Исполним заказ за 2 часа *</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits3.svg" />
                    </div>
                    <div className="benefits-text">Оперативно Доставим</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits4.svg" />
                    </div>
                    <div className="benefits-text">Эксклюзивные букеты по индивидуальным пожеланиям</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits5.svg" />
                    </div>
                    <div className="benefits-text">Укомплектуем подарок открыткой и шарами</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits6.svg" />
                    </div>
                    <div className="benefits-text">#ВериБерри_клуб для постоянных клиентов</div>
                </li>
                <li>
                    <div className="benefits-img animated bounce">
                        <img alt="" src="/img/icon/benefits7.svg" />
                    </div>
                    <div className="benefits-text">Специальные предложения для новых клиентов</div>
                </li>
            </ul>
            <div className="clearfix"></div>
        </Fragment>
    );
}