import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
//import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import createRootReducer from 'reducers';

export const history = createBrowserHistory();

export const configureStore = preloadedState => createStore(
    createRootReducer(history),
    preloadedState,
    compose(
        //composeWithDevTools(
        applyMiddleware(
            thunk,
            routerMiddleware(history)
        )
    )
);
