import React, { Fragment } from 'react';

export default () => {
    return (
        <Fragment>
            <h2 className="hcenter insth2">
                <span>#ВериБерри</span>
            </h2>
            <ul className="inst">
                <li><img alt="" src="/img/catalog/scl9rJQHqWk.jpg" /></li>
                <li><img alt="" src="/img/catalog/7M5kqXpOhhI.jpg" /></li>
                <li><img alt="" src="/img/catalog/5WZvBMCzTWw.jpg" /></li>
                <li><img alt="" src="/img/catalog/J0HWv8vBfZ8.jpg" /></li>
                <li><img alt="" src="/img/catalog/c9gEncd5ois.jpg" /></li>
                <li><img alt="" src="/img/catalog/41U4A7OEdyk.jpg" /></li>
            </ul>
            <div className="clearfix"></div>
        </Fragment>
    );
}