import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';

import CustomFunc from 'addJquery';

export default class Balloons extends Component {

    componentDidMount() {
        CustomFunc();
    }
    componentDidUpdate() {
        CustomFunc();
    }
    render() {
        return (
            <Fragment>
                <div class="wrapper" id="tophead">
                    <Header />
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="breadCrumbs">
                                    <li><Link to="/">Главная</Link></li>
                                    <li><Link to="/balloons-postcards-toys">Дополнительно</Link></li>
                                    <li><Link className="active" to="/balloons">Шары</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <h1 className="hcenter mt30">
                        <span>Шары</span>
                    </h1>
                    <div className="container">
                        <div className="row balloons">
                            <div className="col-12">
                                <p className="bold">Воздушные шары это наше новое и легкое направление!</p>
                                <p>Всегда в наличии латексные шары, наполненные гелием и фольгированные шары, подберем под цветовую гамму букета или Ваши пожелания. С удовольствием оформим помещения под Ваше торжество, а также создадим уникальные фотозоны и арт объекты!</p>
                                <p>Шар латексный, наполненный гелием 80 рублей</p>
                                <p>Шар фольгированный (шар, сердце, звезда) 150 рублей</p>
                                <p>Большой выбор, оперативность и доставка!</p>
                                <p>Наличие цветов и дизайнов шариков необходимо уточнять по телефону 8-927-298-94-52</p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/1.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/2.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/3.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/4.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/5.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/6.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/7.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/8.jpg" />
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <img alt="" src="/img/balloons/9.jpg" />
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <Veryberry />
                    <div className="substrate-footer"></div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}