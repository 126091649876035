import $ from 'jquery';
//import 'slick-carousel';

export default function CustomFunc() {
    $(document).ready(function () {
        /*Адаптированное меню*/
        $(".touch-menu").click(function () {
            $(this).toggleClass("on");
            $(".nav").stop().slideToggle();
            return false;
        });

        /*Появление скрытово .nav*/
        $(window).resize(function () {
            var wid = $(window).width();
            var menu = $('.nav');
            if (wid > 890 && menu.is(':hidden')) {
                menu.removeAttr('style');
            }
        });
        /*Появление скрытово .nav*/
        /*Адаптированное меню*/

        /*Footer-height*/
        var hFooter = $('.footer').height();
        $('.substrate-footer').height(hFooter);
        /*Footer-height*/

        /*Scroll в сайта вверх страницы*/
        $('a[href*="#"]').bind("click", function (e) {
            var anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $(anchor.attr('href')).offset().top
            }, 1000);
            e.preventDefault();
            return false;
        });
        /*Scroll в сайта вверх страницы*/
        /*Фильтр
        $(".filter-a").click(function () {
            $(this).toggleClass("active");
            $(".filter").toggleClass("active");
            return false;
        });
        /*Вкладки*/
        $(".tab-menu_list").each(function (i) {
            $(this).attr("href", "#tab" + i);
        });
        $(".tab-content-list").each(function (i) {
            $(this).attr("id", "tab" + i);
        });
        $(".tab-menu li").click(function (e) {
            e.preventDefault();
            $(".tab-menu .active").removeClass("active");
            $(this).addClass("active");
            var tab = $(this).find("a").attr("href");
            $(".tab-content-list").not(tab).css({ "display": "none" });
            $(tab).fadeIn(200);
        });
        /*Вкладки2*/
        // $(".size-sm_list").each(function (i) {
        //     $(this).attr("href", "#size" + i);
        // });
        // $(".print-list").each(function (i) {
        //     $(this).attr("id", "size" + i);
        // });
        // $(".size-sm li").click(function (e) {
        //     e.preventDefault();
        //     $(".size-sm .active").removeClass("active");
        //     $(this).addClass("active");
        //     var size = $(this).find("a").attr("href");
        //     $(".print-list").not(size).css({ "display": "none" });
        //     $(size).fadeIn(200);
        // });
        // if ($('.product-img')) $('.product-img').slick({
        //     slidesToShow: 1,
        //     slidesToScroll: 1
        // });
        // if ($('.product-imgMini')) $('.product-imgMini').slick({
        //     slidesToShow: 5,
        //     slidesToScroll: 1,
        //     asNavFor: '.product-img',
        //     focusOnSelect: true
        // });
        // try {
        //     if ($('.banner')) $('.banner').slick({
        //         dots: true,
        //         arrows: false,
        //         autoplay: true,
        //         autoplaySpeed: 3000
        //     });
        // } catch (e) {
        //     //window.location.reload();
        // }
    });
}