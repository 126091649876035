import { fetchData } from 'api';


export const fetchBouquets = () => dispatch => {
    dispatch({ type: 'LOAD_DATA_START' });
    return fetchData('bouquets')
        .then(data => {
            if (data) {
                dispatch({ type: 'FETCH_BOUQUETS_SUCCESS', payload: data });
            }
            return dispatch({ type: 'LOAD_DATA_END' });
        });
}

export const fetchFaq = () => dispatch => {
    dispatch({ type: 'LOAD_DATA_START' });
    return fetchData('faq')
        .then(data => {
            if (data) {
                dispatch({ type: 'FETCH_FAQ_SUCCESS', payload: data });
            }
            return dispatch({ type: 'LOAD_DATA_END' });
        });
}

export const fetchPartners = () => dispatch => {
    dispatch({ type: 'LOAD_DATA_START' });
    return fetchData('partners')
        .then(data => {
            if (data) {
                dispatch({ type: 'FETCH_PARTNERS_SUCCESS', payload: data });
            }
            return dispatch({ type: 'LOAD_DATA_END' });
        });
}

export const fetchSlides = () => dispatch => {
    dispatch({ type: 'LOAD_DATA_START' });
    return fetchData('slides')
        .then(data => {
            if (data) {
                dispatch({ type: 'FETCH_SLIDES_SUCCESS', payload: data });
            }
            return dispatch({ type: 'LOAD_DATA_END' });
        });
}

export const setToState = data => dispatch => {
    dispatch({ type: 'LOAD_DATA_START' });
    dispatch({ type: 'DATA_TO_STATE', payload: data });
    return dispatch({ type: 'LOAD_DATA_END' });
}
