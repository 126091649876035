import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import CustomFunc from 'addJquery';

export default class Contacts extends Component {

    componentDidMount() {
        CustomFunc();
    }
    componentDidUpdate() {
        CustomFunc();
    }
    render() {

        const mapState = {
            center: [53.229872, 50.180754],
            zoom: 16,
        };

        return (
            <Fragment>
                <div class="wrapper" id="tophead">
                    <Header />
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="breadCrumbs">
                                    <li><Link to="/">Главная</Link></li>
                                    <li><Link className="active" to="/contacts">Контакты</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div class="product">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="text-content">
                                        <h1>Контакты</h1>
                                        <p>Наша вкусная мастерская «VeryBerry» находится в географическом центре города на главной транспортной артерии по адресу:</p>
                                        <p>г.Самара, ул. Ново-Садовая 265а, 2 этаж<br />
                                            Ориентир – на противоположной стороне от отеля Ренессанс, на дублере.</p>
                                        <p>Электронная почта: <a href="mailto:info@veryberryart.ru">info@veryberryart.ru</a></p>
                                        <p>Телефон: <b>8-927-298-94-52</b></p>
                                        <div className="content-icon">
                                            <a href="https://vk.com/veryberryart" className="top-vk" target="_blank" rel="noopener noreferrer"><img alt="" src="/img/vk.png" /></a>
                                            <a href="https://www.instagram.com/veryberry_art/" className="top-inst" target="_blank" rel="noopener noreferrer"><img alt="" src="/img/inst.png" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="text-content">
                                        <div class="k-mt20"></div>
                                        <div class="map">
                                            <YMaps>
                                                <Map defaultState={mapState} style={{
                                                    width: '100%',
                                                    height: '100%'
                                                }}>
                                                    <Placemark
                                                        defaultGeometry={mapState.center}
                                                        modules={['geoObject.addon.balloon']}
                                                        properties={{
                                                            balloonContentBody:
                                                                '<b>VeryBerry</b><br/>Самара, Ново-Садовая, 265а'
                                                        }}
                                                    />
                                                </Map>
                                            </YMaps>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <Veryberry />
                    <div className="substrate-footer"></div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
