import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { fetchSlides } from 'actions';
//import "react-responsive-carousel/lib/styles/carousel.min.css";

class Banner extends Component {

    constructor() {
        super();
        this.state = {
            slides: false,
            device: 'desctop'
        };
    }

    componentDidMount() {
        if (this.props.slides.length === 0) this.props.fetchSlides()
            .then(() => this.setState({ slides: this.props.slides }));
        else this.setState({ slides: this.props.slides });
        this.setDevice();
        window.addEventListener('resize', this.setDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDevice);
    }

    setDevice = () => {
        if (window.innerWidth <= 768) this.setState({ device: 'mobile' });
        else this.setState({ device: 'desctop' });
    }

    render() {
        const carouselSettings = {
            showArrows: false,
            infiniteLoop: true,
            useKeyboardArrows: true,
            autoPlay: true,
            swipeable: true,
            emulateTouch: true,
            showStatus: false,
            showThumbs: false,
            dynamicHeight: true
        };
        return !this.state.slides ? null : <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <Carousel className="banner" {...carouselSettings}>
                        {this.state.slides.map((name, i) => {
                            return <img
                                key={i}
                                src={`/img/slides/${this.state.device}/${name}`}
                                alt="" />
                        })}
                    </Carousel>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        slides: state.slides
    };
};

const mapDispatchToProps = {
    fetchSlides
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
