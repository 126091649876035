import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import { fetchPartners } from 'actions';

class Partners extends Component {

    constructor() {
        super();
        this.state = {
            partners: false
        };
    }

    componentDidMount() {
        if (this.props.partners.length === 0) this.props.fetchPartners()
            .then(() => this.setState({ partners: this.props.partners }));
        else this.setState({ partners: this.props.partners });
    }

    render() {
        const carouselSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            variableWidth: true,
            autoplay: true
        };
        return !this.state.partners ? null : <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 partners">
                        <h2 className="hcenter insth2">
                            <span>Партнеры</span>
                        </h2>
                        <Slider {...carouselSettings}>
                            {this.state.partners.map((item, i) => {
                                return item.link ? <a key={i} href={item.link} target="_blank" rel="noopener noreferrer">
                                    <img src={item.img} alt="" />
                                </a> : <img key={i} src={item.img} alt="" />
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </Fragment >
    }
}

const mapStateToProps = state => {
    return {
        partners: state.partners
    };
};

const mapDispatchToProps = {
    fetchPartners
}

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
