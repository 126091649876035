import 'whatwg-fetch';

const localStorageAvailable = () => {
    try {
        localStorage.setItem('_test_', '_test_');
        localStorage.removeItem('_test_');
        return true;
    }
    catch (e) {
        return false;
    }
}


export const fetchData = name => new Promise((resolve, reject) => {
    // if (localStorageAvailable() && localStorage[name]) {
    //     resolve(JSON.parse(localStorage[name]));
    // } else {
    const init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const url = `${window.location.protocol}//${window.location.host}/data/${name}.json`;
    fetch(url, init)
        .then(response => {
            if (response.ok) return response.json();
            else resolve(false);
        })
        .then(data => {
            if (data) {
                localStorage[name] = JSON.stringify(data);
                resolve(data);
            }
        })
        .catch(() => false);
    // }
});
