import React from 'react';

export default () => {
    return (
        <div className="bannerBusiness">
            <div className="bannerBusinessImg"></div>
            <div className="bannerBusinessText">
                <h2>Для корпоративных клиентов</h2>
                #ВериБерри предлагает несколько вариантов сотрудничества и гибкую систему скидок:
                <ul className="bannerBusinessCheck">
                    <li><span></span>«Вкусный абонемент» - пакетное предложение со специальными ценами для компаний, приобретающих штучные букеты для поздравления сотрудников, партнеров или клиентов в течении какого-либо периода на регулярной основе, по согласованному графику.</li>
                    <li><span></span>«Много сразу» - специальные цены на единовременный оптовый заказ (на праздники, корпоративные мероприятия и др. события).</li>
                    <li><span></span>«Декор» - оформление мероприятий, декоративные композиции на столы, организация фотозон, аэродизайн.</li>
                    <li><span></span>«Досуг» - обучающие мастер-классы по сборке фруктовых, овощных и колбасных букетов, могут быть организованы как на территории «VeryBerry», так и на площадке компании.</li>
                </ul>
            </div>
        </div>
    );
}