import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Veryberry from 'components/Veryberry';
import Footer from 'components/Footer';

import CustomFunc from 'addJquery';

export default class Balloons extends Component {

    componentDidMount() {
        CustomFunc();
    }
    componentDidUpdate() {
        CustomFunc();
    }
    render() {
        return (
            <Fragment>
                <div class="wrapper" id="tophead">
                    <Header />
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="breadCrumbs">
                                    <li><Link to="/">Главная</Link></li>
                                    <li><Link to="/balloons-postcards-toys">Дополнительно</Link></li>
                                    <li><Link className="active" to="/postcards">Открытки</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <h1 className="hcenter mt30">
                        <span>Открытки</span>
                    </h1>
                    <div className="container">
                        <div className="row postcards">
                            <div className="col-12">
                                <p className="bold">Наш запас открыток "На все случаи жизни" поможет выразить Ваши чувства особенно точно и трогательно!</p>
                                <p>Партнерами на данный момент являются <a href="http://artcard-shop.ru/" target="_blank" rel="noopener noreferrer">artcard-shop.ru</a> и местные Самарские художники и мастера Hand Made</p>
                                <p>Коллекция постоянно пополняется!</p>
                                <p>А еще! Мы можем создать только Вашу открытку по индивидуальному дизайну!</p>
                                <p>С полным ассортиментом Вы можете познакомиться связавшись с нами :)</p>
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/2.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/3.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/8.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/9.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/10.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/11.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/12.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/13.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/14.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/19.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/23.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/24.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/26.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/29.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/1.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/4.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/5.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/6.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/15.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/16.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/17.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/18.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/27.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/28.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/30.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/31.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/32.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/33.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/7.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/20.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/21.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/22.jpg" />
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <img alt="" src="/img/postcards/25.jpg" />
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <Veryberry />
                    <div className="substrate-footer"></div>
                </div>
                <Footer />
            </Fragment >
        );
    }
}