const initialState = {
    loadData: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'LOAD_DATA_START':
            return { ...state, loadData: true };
        case 'LOAD_DATA_END':
            return { ...state, loadData: false };
        default:
            return state;
    }
};